import { SellernoteAppRegion } from "@sellernote/_shared/src/types/common/common";

type DefaultSEOKey =
  | "title"
  | "description"
  | "siteName"
  | "author"
  | "publisher"
  | "subject";

/**
 * SEO 값을 커스터마이징하지 않을때 사용하는 기본값
 */
const DEFAULT_SEO: Record<
  SellernoteAppRegion,
  {
    [K in DefaultSEOKey]: string;
  }
> = {
  KR: {
    title: "디지털 포워딩 - 쉽다",
    description:
      "디지털 포워딩 서비스 - 쉽다(ShipDa). 간단한 지역/화물 정보로 즉시 견적조회되는 수입물류 포워딩 업체를 찾으신다면. No.1 디지털 수입물류 포워딩 쉽다",
    siteName: "ShipDa",
    author: "쉽다 ShipDa",
    publisher: "쉽다 ShipDa",
    subject: "포워딩",
  },
  SG: {
    title: "Digital Import Freight Forwarding - Bringoodz",
    description:
      "Digital Freight Forwarding Service - Bringoodz. Looking for an import freight forwarder that can instantly generate a quote just with region and cargo details? No.1 Digital Import Freight Forwarding Bringoodz",
    siteName: "Bringoodz",
    author: "Bringoodz",
    publisher: "Bringoodz",
    subject: "Forwarding",
  },
};

export { DEFAULT_SEO };
